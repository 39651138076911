<template>
  <div class="aggrement">
    <div class="scroll theme-color">
      <div style="text-align: center; margin: 10px">复杂产品资料及警告声明</div>
      <div v-html="content" class="content" />
    </div>
    <div style="text-align: left">
      <p>
        <span class="theme-color">[{{ form.bwm_bvs === "是" ? "已" : "未" }}阅读]</span>
        <span class="link" @click="link('baal')">BVT-BVS Agency Agreement Letter</span>
      </p>
      <p>
        <span class="theme-color">[{{ form.bwm_isda_schedule === "是" ? "已" : "未" }}阅读]</span>
        <span class="link" @click="link('bis')">BVT ISDA Schedule</span>
      </p>
      <p>
        <span class="theme-color">[{{ form.bwm_isda_csa === "是" ? "已" : "未" }}阅读]</span>
        <span class="link" @click="link('biccs')">BVT ISDA CSA CREDIT SUPPORT</span>
      </p>
      <p>
        <span class="theme-color">[{{ form.bwm_mca_chi === "是" ? "已" : "未" }}阅读]</span>
        <span class="link" @click="link('bmc')">BVT MCA CHI</span>
      </p>
      <p>
        <span class="theme-color">[{{ form.bwm_mca_eng === "是" ? "已" : "未" }}阅读]</span>
        <span class="link" @click="link('bme')">BVT MCA ENG</span>
      </p>
      <p>
        <span class="theme-color">[{{ form.dma_asa === "是" ? "已" : "未" }}阅读]</span>
        <span class="link" @click="link('dab')">DMA & ASA BVT</span>
      </p>
      <p>
        <span class="theme-color">[{{ form.isda_2002 === "是" ? "已" : "未" }}阅读]</span>
        <span class="link" @click="link('ima')">2002 ISDA Master Agreement</span>
      </p>
      <div @click="handleAcceptAbove">
        <van-checkbox name="是" shape="square" v-model="form.isda_clean" :disabled="disabled"
          ><div style="padding: 10px; position: ">
            本人已经细阅、明白及接受上述文件的条款
          </div></van-checkbox
        >
      </div>
    </div>
    <van-button
      type="primary"
      style="width: 80%"
      class="btn"
      @click="next"
      :disabled="disabledNext"
    >
      下一步
    </van-button>
  </div>
</template>

<script>
import { onMounted, reactive, watch, ref } from "vue";
import { useStore } from "vuex";
import { Button, Field, CellGroup, Checkbox, Toast } from "vant";
import { useRoute } from "vue-router";
import httpApi from "../../utils/httpApi";

let submitForm;
const content = `
你所参与交易的产品属于复杂产品，产品类别是股票互换和股票篮互换交易合约，属于非保本产品。卖方在协议期间将参照标的资产的总收益转移给买方，买方则承诺向对方交付协议固定金额。
产品不设第二市场，你只可以经过交易对手进行交易。产品类别潜在回报将取决于标的资产的回报和杠杆倍数，并不设回报上限。由于产品有杠杆倍数，你将会面临杠杆风险，你的损失可大于投资金额，并会产生追加保证金。如资产的交易货币与结算货币不同，你将会面临汇率风险。另外，产品属于非交易所交易的复杂产品，你将会面临交易对手风险。股权总收益互换合约只供专业投资者买卖，你必须了解该复杂产品的性质、条款、特点及其风险，并审慎行事，详情请参考复杂产品简介。
假如你与 “蜂投金融有限公司或/和蜂投国际投资有限公司(BVI)或/和蜂投财富管理有限公司(BVI)等”（你的对手方）订立场外衍生工具交易，你必须注意，你的对手方并无获证券及期货事务监察委员会（证监会）发牌，因此不受证监会的操守及审慎监管。你亦应注意，你的对手方并无受到任何其他金融监管机构所规管，因此你可能完全不会获得任何监管保障。你应审慎考虑，与你的对手方（而并非与持牌法团）订立场外衍生工具交易是否符合你的最佳利益；如有疑问，你应寻求独立的专业意见。

复杂产品简介
复杂产品的性质：Total Return Swap 衍生品服务(ISDA 协议)
复杂产品的主要条款及特点： 详细资讯以 ISDA 主协议、交易补充协议和主交易确认书为准
产品类别：股票互换和股票篮互换交易
标的资产：可交易标的 A 股或港股(标的股票的法定所有权属于蜂投)
产品风险评级：高风险
交易对手方：蜂投
交易货币：在香港交付的中国法定货币(CNH)或港元(HKD)
结算货币：港元(HKD)或美元(USD)
结算方式：现金结算
产品的主要风险：
市场风险-交易价值会受到市场因素产生不利影响
操作风险-监控及量度相关交易/纪录/责任的系统、措施导致失效而产生损失
融资风险-交易对手资金流动时机出现错配或延误导致没有足够的现金履行责任
信贷风险-交易对手无法按时履行责任
流通风险-场外交易没有充足流动性
价差风险-受订立或终止条件约束，价格可能不是可于其他途径可获得之最佳价格或条件
汇率风险-交易货币与结算货币不同
杠杆风险-损失大于投资金额的风险
交易对手风险-产品属于非交易所交易的复杂产品
产品是否只供专业投资者买卖：是
其他买卖限制：只供投资风险取向分类为进取型及通过衍生投资产品认知评估的客户
产品是否保本：非保本
潜在利益是否有上限或限制：潜在利益无上限，取决于标的资产回报
产品的最坏情况分析：损失大于投资金额，要追加资金
是否有提早终止的特点：有，信用支持规定可能导致额外终止事件，延迟利息和追缴服务费
提早终止合约的罚则：有，请参考 ISDA 主协议
是否有第二市场：没有，只可以经过交易对手进行交易
信用支持规定：如在任何原定交易日，计算代理人确认贷款价值比等于或低于保证金追加水平，计算代理人可向对方手交付保证金追加通知，对方手未能及时转款保证金追加金额或及时向蜂投交付保证金转款证据将导致额外终止事项的发生，并因此赋予蜂投部分或全部提前终止本交易的权利
`.replace(/\r?\n/g, "<br />");
export default {
  name: "aggrement",
  components: {
    [Button.name]: Button,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      content,
    };
  },
  beforeCreate() {
    const { query = {} } = this.$route;
    const { c, e } = query;
    const time = localStorage.getItem("cookieExpires") || 0;
    this.$store.dispatch("handleLogin");
    if (Number(e) > Number(time)) {
      this.$util.setCookieValue(c, e);
    }
  },
  methods: {
    handleAcceptAbove() {
      if (this.form.isda_clean) {
        [
          "bwm_bvs",
          "bwm_isda_schedule",
          "bwm_isda_csa",
          "bwm_mca_chi",
          "bwm_mca_eng",
          "dma_asa",
          "isda_2002",
        ].forEach((item) => {
          if (this.form[item] !== "是") {
            Toast.clear();
            Toast("请先阅读所有文件条款！");
            this.form.isda_clean = false;
          }
        });
      }
    },
    async next() {
      if (this.disabledNext.value) return;
      if (this.disabled) {
        this.$router.push({ path: "/isda/account" });
      } else {
        const params = JSON.parse(JSON.stringify(this.form));
        params.isda_clean = params.isda_clean ? "是" : "否";
        const result = await this.$api.postAgreementState(params);
        const { code = 201, msg = "操作失败" } = result || {};
        if (code === 200) {
          this.$router.push({ path: "/isda/account" });
        } else {
          this.$toast(msg);
        }
      }
    },
    link(path) {
      this.$router.push({ path: `/isda/${path}` });
    },
  },
  setup() {
    const form = reactive({
      bwm_bvs: "", // BWM-BVS Agency Agreement Letter
      bwm_isda_schedule: "", // BWM ISDA Schedule
      bwm_isda_csa: "", // BWM ISDA CSA CREDIT SUPPORT
      bwm_mca_chi: "", // BWM MCA CHI
      bwm_mca_eng: "", // BWM MCA ENG
      dma_asa: "", // DMA & ASA BWM
      isda_2002: "", // 2002 ISDA Master Agreement
      isda_clean: false, // 本人/公司已细阅、明白、接受签署上述所有文件条款
    });
    const disabledNext = ref(true);
    const disabled = ref(false);
    onMounted(async () => {
      const store = useStore();
      store.commit("setTitleValue", "ISDA协议");
      store.commit("setStepsShow", false);
      const { query = {} } = useRoute();
      const { c } = query;
      if (c) {
        store.commit("setTitleLeft", false);
      } else {
        store.commit("setTitleLeft", true);
      }
      const res = await httpApi.getAgreementState();
      if (res) {
        Object.getOwnPropertyNames(form).forEach((item) => {
          if (item === "isda_clean") {
            form[item] = res.data[item] === "是";
          } else if (res.data[item]) {
            form[item] = res.data[item];
          }
        });
      }
      disabled.value = await httpApi.getIsdaDisabled();
    });
    watch(form, (val) => {
      submitForm = { ...val };
      disabledNext.value = false;
      Object.getOwnPropertyNames(submitForm).forEach((item) => {
        if (item === "isda_clean") {
          if (!submitForm[item]) disabledNext.value = true;
        } else if (submitForm[item] !== "是") {
          disabledNext.value = true;
        }
      });
      httpApi.formLog(submitForm, disabledNext);
    });
    return {
      form,
      disabledNext,
      disabled,
    };
  },
};
</script>

<style lang="less">
.aggrement {
  height: calc(100vh - 46px);
  box-sizing: border-box;
  max-width: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .btn {
    margin: 0 auto;
    margin-top: 0;
  }
  .scroll {
    flex-basis: calc(100vh - 400px);
    overflow-y: scroll;
    text-align: left;
    margin-bottom: 10px;
  }
  p {
    line-height: 26px;
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
